import React from "react";
import buybtn from "../../assets/buybtn.svg";
import mainImage from "../../assets/car covers/main-image.webp";
import budge from "../../assets/car covers/budge.webp";
import favato from "../../assets/car covers/favato.webp";
import kayme from "../../assets/car covers/kayme.webp";
import kayme2 from "../../assets/car covers/kayme2.webp";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import FeatureDropdown from "../Daynamic_Components/FeatureDropdown";



export default function Product4({ }: // videos = [],
  any) {

  return (
    <div className="mt-[150px] md:w-[85%] w-[90%] mx-auto mb-28 overflow-hidden">
      <div className="mb-[24px]">
        <div className="md:w-[80%] w-[100%] mx-auto rounded-[12px]  flex items-center justify-center">
          <img src={mainImage} alt="Main Camera" />
        </div>
      </div>


      <div className="w-full items-center justify-center my-8">
        <h1 className="text-[#3D5B96] md:text-[42px] text-[25px] mb-[80px] font-bold w-full text-center">
          أغطية السيارات للحماية من الغبار والعوامل الخارجية
        </h1>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown
          title="المقدمة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              هل تواجه مشكلة تراكم الغبار على سيارتك بسرعة؟ غالبًا ما لا تبقى السيارة نظيفة لفترة طويلة بعد غسلها، فقد تتركها لمدة أسبوع فقط لتجدها مغطاة بالغبار مرة أخرى، هذا الأمر قد يكلفك الكثير من المال إذا كنت تعتمد على الغسيل المتكرر للحفاظ على نظافتها، في هذا المقال سنقدم لك حلاً عمليًا وبسيطا يساعدك على تقليل هذه المشكلة.
            </p>
          </div>
        </FeatureDropdown>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown
          title="أغطية السيارات"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              أغطية السيارات هي الحل الذي تحتاجه للحفاظ على نظافة سيارتك وحماية الطلاء لفترة أطول، وهناك العديد من أنواع الأغطية التي تقدم حمايات مختلفة للسيارات وتختلف في الجودة والسعر والاستخدام. سنذكر الخصائص التي تحتاجها للحصول على الحماية المناسبة من: الغبار والأوساخ، أشعة الشمس، الماء والرطوبة وغيرها، مع تقديم بعض المنتجات.
            </p>
          </div>
        </FeatureDropdown>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown
          title="الخصائص التي تحتاجها للحصول على حماية من:"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-[85%]">

            <FeatureDropdown title="الغبار والأوساخ"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  يعد استخدام مواد محكمة النسج مثل الفايبر متعدد الطبقات ضروري لحماية السيارة من الغبار، ولكن يجب التأكد أيضا من تغطية السيارة بالكامل بإحكام لتجنب تسرب الأتربة، ويفضل أن يكون الغطاء مرنًا من الجوانب حتى يسهل عليك تغطية جوانب السيارة من الأسفل.
                </p>
              </div>
            </FeatureDropdown>

            <FeatureDropdown title="الماء والرطوبة"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  معظم أغطية السيارات التي تقدم حماية من المطر تكون مقاومة للماء فقط وليست مضادة للماء، وهذا قد يكون أفضل لأن الغطاء غير القابل للتهوية يمكن أن يحبس الماء والرطوبة، مما قد يسبب التعفن، لهذا يُفضل اختيار غطاء يحتوي على فتحات تهوية لمنع تكوّن الرطوبة التي قد تسبب بقعًا مائية أو تعفنًا داخل السيارة.
                </p>

                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  لضمان تهوية جيدة، اختر غطاءً متعدد الطبقات مصنوعًا من القماش المنسوج وذو سماكة قليلة ليسمح بتبخر الماء والرطوبة.
                </p>
              </div>
            </FeatureDropdown>

            <FeatureDropdown title="الخدوش"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  يجب أن تكون الطبقة الداخلية ناعمة حتى لا تتسبب في خدش طلاء السيارة عند احتكاك الغطاء بطلاء السيارة.
                </p>

                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  المواد الموصى بها: البولي بروبلين، صوف النايلون، أو القطن.
                </p>
              </div>
            </FeatureDropdown>

            <FeatureDropdown title="أشعة الشمس"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  للحماية الفعالة من أشعة الشمس، يُفضل أن يكون الغطاء مصنوعًا من البوليستر بخصائص عاكسة للضوء، أو من فايبر الأكريليك المعروف بمقاومته الطبيعية لأشعة الشمس.
                </p>
              </div>
            </FeatureDropdown>

          </div>

        </FeatureDropdown>

      </div>


      <div className="mb-[24px]">
        <FeatureDropdown title="المنتجات"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

          <div className="w-[85%]">
            <FeatureDropdown title="بودج (Budge)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-[85%]">

                <FeatureDropdown title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <img src={budge} alt="" />
                </FeatureDropdown>

                <div className="w-screen">

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>اللون:</b> رمادي
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>العلامة التجارية:</b> بادج
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>المادة:</b> البولي بروبلين
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>الحجم:</b> يوجد أحجام متعددة للاختيار منها
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>الحماية من:</b>
                  </p>

                  <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                    <li>أشعة الشمس</li>
                    <li>الغبار والأوساخ</li>
                    <li>الخدوش (لا تسبب خدش للسيارة)</li>
                    <li>الرطوبة والعث والعفن الفطري (بسبب وجود التهوية)</li>
                    <li>الماء (مقاومة الماء)، ولكن تعتمد على الحجم الذي تختاره لأن بعضها غير مقاوم للماء</li>
                  </ul>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>التقييم:</b> 4.2 (13,100)
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>إمكانية الإرجاع:</b> يمكنك إرجاع المنتج خلال 15 يومًا.
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>السعر:</b> يعتمد على الحجم ولكن تتراوح الأسعار بين 190 – 400 ريال
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>شراء:</b>
                  </p>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/49PGFKt">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

          </div>

          <div className="w-[85%]">
            <FeatureDropdown title="فافوتو (favato)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-[85%]">

                <FeatureDropdown title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <img src={favato} alt="" />
                </FeatureDropdown>

                <div className="w-screen">

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>اللون:</b> فضي
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>العلامة التجارية:</b> فافوتو
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>المادة:</b> قطن
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>الحجم:</b> 4.34m x 1.83m x 1.6m
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>الحماية من:</b>
                  </p>

                  <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                    <li>أشعة الشمس</li>
                    <li>الغبار والأوساخ</li>
                    <li>الخدوش (لا تسبب خدش للسيارة)</li>
                    <li>الماء (مقاومة للماء)</li>
                  </ul>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>المميزات:</b>
                  </p>

                  <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                    <li><b>سحاب جانبي:</b> يوجد سحاب يسهل عليك الدخول دون إزالة الغطاء بشكل كامل</li>
                    <li><b>مادة شديدة التحمل:</b> مصنوعة من 5 طبقات من مواد عالية الجودة</li>
                    <li><b>أشرطة أمان سفلية:</b> يوجد سلك مرن في أسفل الغطاء و أربعة أشرطة تأمين لجعل الغطاء أكثر متانة</li>
                  </ul>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>التقييم:</b> 4.4 (1,199)
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>إمكانية الإرجاع:</b> يمكنك إرجاع المنتج خلال 15 يومًا.
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>السعر:</b> 464 ريال
                  </p>

                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>شراء:</b>
                  </p>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/49VApRh">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

          </div>

          <div className="w-[85%]">
            <FeatureDropdown title="كايم (kayme)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-screen">
                <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                  يوجد بائعين، واحد يقدم خيارات متنوعة في الألوان والأحجام ولكن بسعر أعلى، والآخر يقدم خيارات أقل ولكن بسعر أقل وتقييم أعلى.
                </p>
              </div>

              <div className="w-[95%]">
                <FeatureDropdown title="بائع 1"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <div className="w-[85%]">

                    <FeatureDropdown title="صورة"
                      customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                      <img src={kayme} alt="" />
                    </FeatureDropdown>

                    <div className="w-screen">

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>اللون:</b> توجد عدة ألوان للاختيار منها
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>العلامة التجارية:</b> كايم
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>المادة:</b> قطن
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>الحجم:</b> يوجد أحجام متعددة للاختيار منها
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>الحماية من:</b>
                      </p>

                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                        <li>أشعة الشمس</li>
                        <li>الغبار والأوساخ</li>
                        <li>الخدوش (لا تسبب خدش للسيارة)</li>
                        <li>الماء (مقاومة للماء)</li>
                      </ul>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>المميزات:</b>
                      </p>

                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                        <li>مصنوع من 6 طبقات من خامة عالية الجودة</li>
                        <li>سحاب جانبي للماء لتسهيل الدخول إلى السيارة دون إزالة الغطاء بالكامل</li>
                      </ul>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>التقييم:</b> 4.2 (4,400)
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>إمكانية الإرجاع:</b> يمكنك إرجاع المنتج خلال 15 يومًا.
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>السعر:</b> يعتمد على الحجم واللون ولكن تتراوح الأسعار بين 440 – 800 ريال، قد تكون أكثر أو أقل من ذلك.
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>شراء:</b>
                      </p>

                      <div className="w-[85%]">
                        <a href="https://amzn.to/4fzygfe">
                          <button className="mt-4">
                            <img src={buybtn} alt="Buy Button" />
                          </button>
                        </a>
                      </div>
                    </div>

                  </div>
                </FeatureDropdown>
              </div>


              <div className="w-[95%]">
                <FeatureDropdown title="بائع 2"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <div className="w-[85%]">

                    <FeatureDropdown title="صورة"
                      customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                      <img src={kayme2} alt="" />
                    </FeatureDropdown>

                    <div className="w-screen">

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>اللون:</b> فضي
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>العلامة التجارية:</b> كايم
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>المادة:</b> الألومنيوم
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>الحجم:</b> يوجد أحجام متعددة للاختيار منها
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>الحماية من:</b>
                      </p>

                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                        <li>أشعة الشمس</li>
                        <li>الغبار والأوساخ</li>
                        <li>الخدوش (لا تسبب خدش للسيارة)</li>
                        <li>الماء (مقاومة للماء)</li>
                      </ul>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>المميزات:</b>
                      </p>

                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                        <li>مصنوع من 6 طبقات من خامة عالية الجودة</li>
                        <li>سحاب جانبي للماء لتسهيل الدخول إلى السيارة دون إزالة الغطاء بالكامل</li>
                      </ul>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>التقييم:</b> 4.2 (19,600)
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>إمكانية الإرجاع:</b> يمكنك إرجاع المنتج خلال 15 يومًا.
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>السعر:</b> يعتمد على الحجم ولكن تتراوح الأسعار بين 240 – 600 ريال.
                      </p>

                      <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                        <b>شراء:</b>
                      </p>

                      <div className="w-[85%]">
                        <a href="https://amzn.to/3ZVqExY">
                          <button className="mt-4">
                            <img src={buybtn} alt="Buy Button" />
                          </button>
                        </a>
                      </div>
                    </div>

                  </div>
                </FeatureDropdown>
              </div>

            </FeatureDropdown>

          </div>


        </FeatureDropdown>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown title="ملاحظات مهمة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
          <div className="w-screen">
            <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">لضمان أفضل حماية للطلاء، تأكد من أن السيارة نظيفة وجافة قبل تغطيتها، لأن الغبار المتبقي قد يتسبب بخدوش في الطلاء عند الاحتكاك مع الغطاء.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">تجنب الأغطية الرخيصة ذات الجودة المنخفضة، لأنها قد تتشقق أو تصبح غير صالحة للاستخدام خلال فترة قصيرة (شهرين مثلًا). اختر دائمًا أغطية ذات جودة عالية تدوم لفترة طويلة.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">يجب اختيار حجم غطاء مناسب لسيارتك، لأن هناك أحجام مختلفة من الأغطية، فإذا اخترت غطاء بحجم أصغر من سيارتك قد يصعب عليك التركيب والنتائج قد تكون سيئة.</li>
            </ul>
          </div>
        </FeatureDropdown>
      </div>

    </div >
  );
}
