// data.ts

import Product1 from '../Components/ProdoctComponents/Product1'
import cameras from '../assets/cameras/main-image-short.webp'

import Product2 from '../Components/ProdoctComponents/Product2'
import laptopStands from '../assets/laptop stands/main-image.webp'

import Product3 from '../Components/ProdoctComponents/Product3'
import soundCanselling from '../assets/sound canselling/main-image.webp'

import Product4 from '../Components/ProdoctComponents/Product4'
import carCovers from '../assets/car covers/main-image.webp'

import Product5 from '../Components/ProdoctComponents/Product5'
import walletTracker from '../assets/wallet tracker/main-image.webp'

export const articles = [

  { id:1,
    img: cameras,
    title: 'كاميرات المراقبة',
    description: 'أنواع كاميرات المراقبة مع المميزات والسلبيات وثلاث منتجات بسعر جيد',
     category: 'إلكترونيات',
     component:Product1
  },
  { id:2,
    img: laptopStands,
    title: 'حاملات اللابتوب',
    description: 'حاملات اللابتوب وأهميتها وأسعارها مع بعض المنتجات للشراء',
     category: 'إكسسوارات اللابتوب',
     component:Product2
  },
  { id:3,
    img: soundCanselling,
    title: 'عازل الصوت',
    description: 'شرح لكيفية عزل الأصوات والمنتجات التي تساهم في ذلك',
     category: 'عزل الصوت',
     component:Product3
  },
  { id:4,
    img: carCovers,
    title: 'أغطية السيارات للحماية من الغبار والعوامل الخارجية',
    description: 'أغطية السيارات التي توفر الحماية التي تحتاجها من الغبار والعوامل الخارجية',
     category: 'إكسسوارات السيارة',
     component:Product4
  },
  { id:5,
    img: walletTracker,
    title: 'أفضل أنواع أجهزة تعقب المحفظة',
    description: 'أهمية اقتناء جهاز تعقب المحفظة للحفاظ على البطاقات الشخصية وتوفير وقت البحث عند فقدان المحفظة',
     category: 'إلكترونيات',
     component:Product5
  },


];
