import React from "react";
import buybtn from "../../assets/buybtn.svg";
import mainImage from "../../assets/wallet tracker/main-image.webp";
import budge from "../../assets/wallet tracker/budge.webp";
import eufy from "../../assets/wallet tracker/eufy.webp";
import chipolo from "../../assets/wallet tracker/chipolo.webp";
import tile from "../../assets/wallet tracker/tile.webp";
import airtag from "../../assets/wallet tracker/airtag.webp";
import smarttag from "../../assets/wallet tracker/smarttag.webp";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import FeatureDropdown from "../Daynamic_Components/FeatureDropdown";



export default function Product5({ }: // videos = [],
  any) {

  return (
    <div className="mt-[150px] md:w-[85%] w-[90%] mx-auto mb-28 overflow-hidden">
      <div className="mb-[24px]">
        <div className="md:w-[80%] w-[100%] mx-auto rounded-[12px]  flex items-center justify-center">
          <img src={mainImage} alt="Main Camera" />
        </div>
      </div>


      <div className="w-full items-center justify-center my-8">
        <h1 className="text-[#3D5B96] md:text-[42px] text-[25px] mb-[80px] font-bold w-full text-center">
          أفضل أنواع أجهزة تعقب المحفظة
        </h1>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown
          title="المقدمة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              إن المحفظة ليست مجرد أداة لحمل النقود، بل تحتوي على أشياء مهمة مثل: البطاقات الائتمانية، الهوية الشخصية، الأموال وغيرها. لذا فإن فقدان المحفظة أو نسيان مكانها يمكن أن يؤدي إلى مشكلات كبيرة، مثل: القلق النفسي وإهدار الوقت في استبدال البطاقات، بالإضافة إلى التأثير المالي المحتمل في حال فقدان هذه الأموال.
            </p>
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              لهذا السبب، أصبح اقتناء متعقب للمحفظة ضرورة، فهو يساعدك على تحديد مكان المحفظة بسرعة وسهولة. في هذا المقال، سنستعرض العوامل المحددة لشراء جهاز تتبع المواقع، الإيجابيات والسلبيات، بالإضافة إلى تفاصيل أشهر الأجهزة المتوفرة في السوق.
            </p>
          </div>
        </FeatureDropdown>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown
          title="طريقة عمل جهاز بطاقة تعقب المحفظة"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3"
        >
          <div className="w-screen">
            <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
              الأجهزة التي تعمل مع نظام أبل ترسل إشارة إلى أجهزة أبل القريبة ثم يتم إرسال الإحداثيات إلى مركز أبل ثم تعيد إرسال الإحداثيات إليك، ونفس الشيء يحدث مع أجهزة أندرويد ولكنها تستخدم أجهزة أندرويد القريبة.
            </p>
          </div>
        </FeatureDropdown>
      </div>

      <div className="mb-[24px]">
        <FeatureDropdown title="الإيجابيات"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
          <div className="w-screen">
            <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">سهولة العثور على المحفظة عند فقدها داخل المنزل أو خارجه.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">توفير الوقت والجهد عند البحث عن المحفظة.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">سهولة استخدام التطبيق في تتبع المحفظة.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">تصميم خفيف ومتين يناسب المحفظات.</li>
            </ul>
          </div>
        </FeatureDropdown>
      </div>

      <div className="mb-[24px]">
        <FeatureDropdown title="السلبيات"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
          <div className="w-screen">
            <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">بعض الأجهزة تتطلب اشتراكاً شهرياً للحصول على جميع الميزات.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">بعض الأجهزة تكون بدون شاحن أو عمر بطارية قليل نسبيا.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">معظم الأجهزة المقترحة لا توفر التوافق مع جميع أنواع الجوالات.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">معظم الأجهزة ليست دقيقة تماماً في تحديد المواقع.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">إذا لم تكن هناك جوالات أو أجهزة قريبة لا يمكن تحديد الموقع، ولكن بما أن الجوالات كثيرة فيعتبر الجهاز عملي.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">قد لا تكون عملية في حالة السرقة إذا استخدمت صوت، ولكنها جيدة في تحديد الموقع وهذا قد يفيدك في استعادة المحفظة.</li>
            </ul>
          </div>
        </FeatureDropdown>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown title="أسباب شراء المنتج"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
          <div className="w-screen">
            <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">إذا كنت تخشى فقدان بطاقاتك المهمة وأموالك.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">لتوفير الوقت والجهد في البحث عن المحفظة.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">لتقليل القلق الناتج عن فقدان المحفظة.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">إذا كنت تنسى مكان المحفظة بشكل متكرر خاصة في خارج المنزل.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">إذا كنت تعيش في منطقة ذات معدلات سرقة منخفضة وتحتاج إلى وسيلة سهلة لتحديد مكان محفظتك.</li>
            </ul>
          </div>
        </FeatureDropdown>
      </div>



      <div className="mb-[24px]">
        <FeatureDropdown title="المنتجات"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

          <div className="w-[85%]">
            <FeatureDropdown title="يوفي (eufy)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-[85%]">

                <FeatureDropdown title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <img src={eufy} alt="" />
                </FeatureDropdown>

                <div className="w-screen">

                  <div className="mb-[24px]">
                    <div className="w-screen">
                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">بطارية تدوم حتى 3 سنوات حسب المواصفات، ولكن بعض المستخدمين أشاروا إلى أن المدة الفعلية حول سنة واحدة.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">يعمل مع أجهزة أبل فقط.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">إمكانية العثور على الهاتف حتى في وضع الصامت، يمكن إصدار صوت من الجوال للعثور عليه عن طريق نقر الجهاز بنقرة مزدوجة.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">يوفر تطبيق خاص للجهاز كبديل لتطبيق find my.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">مقاوم للماء.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">البطارية غير قابلة للشحن أو الاستبدال.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">المادة: معدن</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">سمك: 2.4 ملم.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">التقييم: 4 (2,784 تقييم)</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">السعر: 84 ريال. </li>
                      </ul>
                    </div>
                  </div>


                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>شراء:</b>
                  </p>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/4gEMDR6">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>
          </div>

          <div className="w-[85%]">
            <FeatureDropdown title="تشيبولو (chipolo)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-[85%]">

                <FeatureDropdown title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <img src={chipolo} alt="" />
                </FeatureDropdown>

                <div className="w-screen">

                  <div className="mb-[24px]">
                    <div className="w-screen">
                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">يعمل فقط مع تطبيق find my.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">بطارية تدوم حتى سنتين.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">مقاوم للماء.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">صوت قوي 105 ديسيبل.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">يوفر إشعارات عند ترك المحفظة خلفك.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">البطارية غير قابلة للشحن أو الاستبدال.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">المادة: بلاستيك.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">سمك: 2 ملم.</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">التقييم: 3.9 (6,611 تقييم)</li>
                        <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">السعر: 281 ريال.</li>
                      </ul>
                    </div>
                  </div>


                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>شراء:</b>
                  </p>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/3ZWjglS">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

          </div>

          <div className="w-[85%]">
            <FeatureDropdown title="تايل (tile)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-[85%]">

                <FeatureDropdown title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <img src={tile} alt="" />
                </FeatureDropdown>

                <div className="w-screen">

                  <div className="mb-[24px]">
                    <div className="w-screen">
                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">متوافق مع أجهزة أبل وأندرويد.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">بطارية تدوم لمدة 3 سنوات ولكن بالتجربة تأخذ بين سنتين إلى 3 سنين.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">مقاومة للماء والغبار.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">يتوفر مع تطبيق خاص، ولكن يتطلب اشتراكاً شهرياً لبعض الخدمات المهمة.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">لا يمكن استبدال بطاريتها، ولكن بعض الأنواع يمكنك استبدال بطاريتها.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">المادة: بلاستيك.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">سمك: 3 ملم.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">التقييم: 4.4 (12,641 تقييم)</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">السعر: 140 ريال.</li>
                      </ul>
                    </div>
                  </div>


                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>شراء:</b>
                  </p>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/4gNefU3">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

          </div>

          <div className="w-[85%]">
            <FeatureDropdown title="ابل اير تاغ (apple airtag)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-[85%]">

                <FeatureDropdown title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <img src={airtag} alt="" />
                </FeatureDropdown>

                <div className="w-screen">

                  <div className="mb-[24px]">
                    <div className="w-screen">
                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">يوفر تحديداً دقيقاً مقارنة بالأجهزة الأخرى باستخدام تكنولوجيا النطاق فائق العرض.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">تعد الأكثر مبيعا في مكتشف المواد في أمازون.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">التقييم: 4.6 (3,618 تقييم)</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">السعر: 129 ريال.</li>
                      </ul>
                    </div>
                  </div>


                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>شراء:</b>
                  </p>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/4gEN5yM">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

          </div>

          <div className="w-[85%]">
            <FeatureDropdown title="سامسونج سمارت تاج 2 (Samsung SmartTag 2)"
              customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">

              <div className="w-[85%]">

                <FeatureDropdown title="صورة"
                  customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
                  <img src={smarttag} alt="" />
                </FeatureDropdown>

                <div className="w-screen">

                  <div className="mb-[24px]">
                    <div className="w-screen">
                      <ul className="list-disc pr-5 text-[#3D5B96] md:text-[18px] text-[15px] w-[85%] md:w-[80%]">
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">مصمم للعمل مع أجهزة Samsung Galaxy فقط.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">مقاوم للماء والغبار.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">بطارية تدوم حتى 500 يوم.</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">التقييم: 4.3 (6,267 تقييم)</li>
                      <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3">السعر: 99 ريال.</li>
                      </ul>
                    </div>
                  </div>


                  <p className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] w-[85%] md:w-[80%] m-3">
                    <b>شراء:</b>
                  </p>

                  <div className="w-[85%]">
                    <a href="https://amzn.to/4a2oXUd">
                      <button className="mt-4">
                        <img src={buybtn} alt="Buy Button" />
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </FeatureDropdown>

          </div>


        </FeatureDropdown>
      </div>


      <div className="mb-[24px]">
        <FeatureDropdown title="العوامل المحددة للاختيار"
          customStyles="w-full flex justify-between items-center text-[18px] bg-[#c7d5e6] font-bold px-4 py-2 rounded-[8px] text-[#3D5B96] focus:outline-none text-[#3D5B96] md:text-[18px] text-[12px] leading-[30px] my-3">
          <div className="w-screen">
            <ul className="list-disc pr-5 text-[#3D5B96] w-[85%] md:w-[80%]">
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3"><b>التوافق مع الأجهزة:</b> تأكد من أن الجهاز متوافق مع نظام جوالك.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3"><b>عمر البطارية:</b> اختر جهازاً يدوم طويلاً أو يكون قابلا للشحن أو استبدال البطارية.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3"><b>الصوت:</b> قوة الصوت مهمة لتحديد موقع الجهاز بسهولة.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3"><b>المزايا الإضافية:</b> مثل مقاومة الماء، تطبيق مخصص، وإمكانية تحديد الموقع بدقة.</li>
              <li className="text-[#3D5B96] md:text-[18px] text-[15px] leading-[30px] m-3"><b>التكلفة:</b> ضع في اعتبارك السعر والمنتجات التي تقدم خدماتها دون اشتراكات شهرية.</li>
            </ul>
          </div>
        </FeatureDropdown>
      </div>

    </div >
  );
}
